
import { defineComponent, ref } from "vue"
import * as Yup from "yup"
import { ErrorMessage, Field, Form } from "vee-validate"
import ApiService from "@/core/services/ApiService"
import router from "../../router/index"
import store from "@/store"
import { Mutations } from "@/store/enums/StoreEnums"

export interface SelectAccount {
  accountNumber: number
}

export default defineComponent({
  name: "AccountSelect",
  components: {
    ErrorMessage,
    Field,
    Form
  },
  setup() {
    const selectAccount = ref<SelectAccount>({
      accountNumber: 0
    })

    const sendRequest = async () => {
      ApiService.setHeader()

      const account = await ApiService.get(
        `/accounts/${ref(selectAccount).value.accountNumber}`
      )

      if (account.data.data.id) {
        store.commit(Mutations.SET_ACTIVE_ACCOUNT, account.data)
        router.push({ name: "status", params: { id: account.data.data.id } })
      } else {
        window.alert("Account not found")
        router.push({ name: "account-select" })
      }
    }

    const selectAccountValidator = Yup.object().shape({
      accountNumber: Yup.number()
        .required()
        .label("Account Number")
    })

    const submitSelectAccountRequest = ref<HTMLElement | null>(null)

    const saveSelectAccount = async () => {
      if (submitSelectAccountRequest.value) {
        // Activate indicator
        submitSelectAccountRequest.value.setAttribute("data-kt-indicator", "on")

        sendRequest()
      }
    }

    return {
      selectAccountValidator,
      submitSelectAccountRequest,
      saveSelectAccount,
      selectAccount
    }
  }
})
